.dashboard-btn-success {
  background-color: rgb(71, 179, 57);
}
.dashboard-btn-pending {
  background-color: #d8d519;
}
.dashboard-btn-failed {
  background-color: rgb(199, 0, 0);
}
.dashboard-icon-50px {
  margin-bottom: 25px;
  width: 52px;
  height: 52px;
  border-radius: 50%;
  background-color: var(--theme-deafult);
  margin-right: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  cursor: pointer;
}

.hospital-small-chart {
  .dashboard-icon-success50px {
    margin-bottom: 25px;
    width: 52px;
    height: 52px;
    border-radius: 50%;
    background-color: #51bb25;
    margin-right: 15px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;
    cursor: pointer;
  }
  .dashboard-icon-pending50px {
    margin-bottom: 25px;
    width: 52px;
    height: 52px;
    border-radius: 50%;
    background-color: #f8d62b;
    margin-right: 15px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;
    cursor: pointer;
  }
  .dashboard-icon-failed50px {
    margin-bottom: 25px;
    width: 52px;
    height: 52px;
    border-radius: 50%;
    background-color: #dc3545;
    margin-right: 15px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;
    cursor: pointer;
  }
}

.dashboard-table {
  text-align: center;
  margin-bottom: 25px;
}
