.user-detail-solid-input{
    background-color: #ced4da !important;
}
.user-detail-mutation-media{
    text-align: center;
}
.user-detail-media-custom-width{
    width: 100px !important;
    border: 1px solid black;
}
.personal-information-modal-photo:hover{
    cursor: pointer;
}

.email-wrap{
    .text-black{
        p{
            color: black !important;
        }
    }
}

#data-ktp{
    p {
        margin-left: 20px;
    }
}