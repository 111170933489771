.maintenance-container{
    .card-maintenance{
        box-shadow: -3px 4px 15px -6px rgba(0, 0, 0, 0.25);
    }
    .alert-information{
        color: #dc3545;
    }
    .time-information{
        color: black;
    }
    .text-information{
        color: black;
    }
}